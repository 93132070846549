<template>
  <div>
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div class="card-title mb-0">
          <h5 class="font-weight-bold mb-0">Accomplishment Report</h5>
        </div>

        <div>
          <div class="form-row">
            <div class="col-auto">
              <div class="form-group">
                <label for="" class="invisible">Add Accomplishment</label>
                <button
                  class="btn form-control btn-primary"
                  style="background-color: #321fdb; color: #fff"
                  @click="openAddAccomplishmentReportModal"
                >
                  <i class="bi bi-plus-lg mr-1"></i> Add accomplishment
                </button>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label for="" class="invisible">Manage IPCR Indicators</label>
                <button
                  class="btn form-control btn-outline-secondary text-dark mr-3"
                  @click="openIpcrIndicatorsModal"
                >
                  <i class="bi bi-sliders2 mr-1"></i> Manage IPCR Indicators
                </button>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group ml-3">
                <label for="">IPCR Indicator</label>
                <select
                  v-model="params.ar_ipcr_indicator_id"
                  class="form-control col-auto"
                  required
                >
                  <option value="" selected>All</option>
                  <option
                    v-for="(option, i) in ipcrIndicators"
                    :key="i"
                    :value="option.ar_ipcr_indicator_id"
                  >
                    {{ option.ipcr_text }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label class="">From</label>
                <input class="form-control" type="date" v-model="params.from" />
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label class="">To </label>
                <input class="form-control" type="date" v-model="params.to" />
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="" class="invisible">Button</label>
                <button
                  class="form-control btn"
                  style="background-color: #321fdb; color: #fff"
                  @click="
                    getAccomplishmentReports(DEFAULT_URL, {
                      filterLoader: { enabled: true },
                    })
                  "
                >
                  <span v-if="!filterLoader"
                    ><i class="bi bi-funnel-fill mr-1"></i> Filter</span
                  >
                  <span v-else class="spinner-border spinner-border-sm"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">
          <div class="form-inline">
            <div class="form-group">
              <label for="">No. of rows</label>
              <select
                name=""
                id=""
                class="form-control ml-2"
                v-model="params.per_page"
                @change="
                  getAccomplishmentReports(DEFAULT_URL, {
                    filterLoader: { enabled: false },
                  })
                "
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
              </select>
            </div>

            <button
              class="ml-3 btn btn-outline-secondary text-dark"
              @click="printAR"
            >
              <i class="bi bi-printer-fill mr-1"></i> Print
            </button>
          </div>
          <div class="d-flex justify-content-right">
            <div class="my-auto mr-2">
              <span
                >{{
                  `${pagination.from || 0}-${pagination.to || 0} of ${
                    pagination.total || 0
                  }`
                }}
              </span>
            </div>
            <nav>
              <ul class="pagination mb-0 pagination-sm">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getAccomplishmentReports(pagination.prev_page_url, {
                        filterLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getAccomplishmentReports(pagination.next_page_url, {
                        filterLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm mb-0">
            <caption>
              <strong> Total Tasks Completed: {{ totalTaskCount }} </strong>
            </caption>
            <thead>
              <th>Date</th>
              <th>IPCR Indicator</th>

              <th width="60%">Accomplishment</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr
                v-for="ar in accomplishmentReports"
                :key="ar.ar_accomplishment_id"
              >
                <td>{{ ar.date_of_work | standardDate }}</td>
                <td>{{ ar.ipcr_text }}</td>

                <td>
                  {{ ar.details }}
                  <span v-if="ar.task_count > 1">({{ ar.task_count }})</span>
                </td>
                <td>
                  <a href="#" @click.prevent="openUpdateEntryModal(ar)">
                    <i class="bi bi-pen-fill text-primary mx-1"> </i>
                  </a>

                  <a href="#" @click.prevent="deleteAccomplishment(ar)">
                    <i class="bi bi-trash-fill text-danger mx-1"> </i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="ar" v-show="false">
          <div class="container mt-4">
            <div class="text-right">
              <h6>Printed on: {{ reference }}</h6>
            </div>
            <img
              src="@/assets/logo/header.png"
              alt=""
              style="position: absolute; margin-left: 24%; margin-top: -3%"
            />
            <div class="text-center font-weight-bold" style="margin-top: 100px">
              ACCOMPLISHMENT REPORT
            </div>
            <div class="text-center">Period Covered: {{ getDate }}</div>
            <div>
              Division:
              <span class="font-weight-bold">
                {{ getDivision($store.state.user.personnel_id) }}
              </span>
            </div>
            <div>
              Unit/Section:
              <span class="font-weight-bold">
                {{ getSection($store.state.user.personnel_id) }}
              </span>
            </div>

            <table style="border: 1px solid; width: 100%" class="mt-3">
              <tr style="border: 1px solid">
                <td
                  class="text-center font-weight-bold"
                  colspan="2"
                  style="border: 1px solid"
                >
                  ACCOMPLISHMENTS
                </td>
              </tr>
              <tr style="border: 1px solid">
                <th style="border: 1px solih" class="pl-2">Date</th>
                <th style="border: 1px solid" class="pl-2">Activities/Tasks</th>
              </tr>
              <tr v-for="(date, i) in dates" :key="i" style="border: 1px solid">
                <td class="p-2">
                  {{ date | arDate }}
                </td>
                <td style="border: 1px solid" class="p-2">
                  <ul
                    style="margin-bottom: 1px"
                    v-for="(ar, index) in groupedDates[date]"
                    :key="index"
                  >
                    <li>
                      {{ ar.details }}
                      {{ ar.task_count != 1 ? "(" + ar.task_count + ")" : "" }}
                    </li>
                  </ul>
                </td>
              </tr>
            </table>

            <table style="width: 100%; margin-top: 50px">
              <tr>
                <td>
                  Prepared by:
                  <div class="font-weight-bold mt-4">
                    {{ getPreparedBy($store.state.user.personnel_id) }}
                  </div>
                  <div>
                    {{ getPreparedPosition($store.state.user.personnel_id) }}
                  </div>
                </td>
                <td>
                  Noted/Approved by:
                  <div class="font-weight-bold mt-4">
                    {{ getNoted($store.state.user.personnel_id) }}
                  </div>
                  <div>
                    {{ getNotedPosition($store.state.user.personnel_id) }}
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>

              <tr v-if="!noReviewedBy.includes($store.state.user.personnel_id)">
                <td>
                  Reviewed by:
                  <div class="font-weight-bold mt-4">
                    {{ getReviewed($store.state.user.personnel_id) }}
                  </div>
                  <div>
                    {{ getReviewedPosition($store.state.user.personnel_id) }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div id="add-accomplishment" data-backdrop="static" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container">
              <div class="">
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="font-weight-bold">Accomplishment Report</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="form-group">
                      <label>Date</label>
                      <input
                        type="date"
                        v-model="ar.date_of_work"
                        class="form-control col-auto"
                      />
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group">
                      <label for="">IPCR Indicator</label>
                      <select
                        v-model="ar.ar_ipcr_indicator_id"
                        class="form-control col-auto"
                        required
                      >
                        <option value="" disabled hidden>Choose</option>
                        <option
                          v-for="(option, i) in ipcrIndicators"
                          :key="i"
                          :value="option.ar_ipcr_indicator_id"
                        >
                          {{ option.ipcr_text }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Accomplishment</label>
                  <textarea
                    type="text"
                    rows="3"
                    v-model="ar.details"
                    class="form-control"
                    required
                  />
                  <small class="text-muted form-text"
                    >Provide a description of your accomplishment here</small
                  >
                </div>

                <div class="form-inline mb-3">
                  <label for="">Task Count</label>
                  <input
                    v-model="ar.task_count"
                    type="number"
                    class="form-control ml-2 col-1"
                  />
                </div>

                <button
                  @click.prevent="appendAccomplishment"
                  class="btn btn-outline-primary mt-1 mb-4"
                  v-if="!editMode"
                >
                  <i class="bi bi-plus-circle-fill mr-1"></i> Add to list
                </button>

                <button v-else class="btn btn-primary" @click="updateEntry">
                  Update
                </button>
              </div>

              <div v-if="!editMode" class="mb-3">
                <div class="list-group mb-3">
                  <a
                    v-for="(ar, index) in arData"
                    :key="index"
                    class="list-group-item list-group-item-action flex-column align-items-start"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 font-weight-bold">
                        {{ `${index + 1}. ${ar.ipcr_text}` }}
                      </h6>
                      <a href="#" @click="deleteEntry(index)">
                        <i class="bi bi-trash-fill text-danger"></i
                      ></a>
                    </div>
                    <p class="mb-1">
                      {{ ar.details }}
                    </p>
                    <span style="font-size: 12.5px" class="text-muted"
                      >Task Count: {{ ar.task_count }}</span
                    >
                  </a>
                </div>
                <button
                  id="ar-submit-btn"
                  class="btn btn-primary mt-1"
                  @click="submitAccomplishmentReport"
                  v-if="arData.length"
                >
                  Save entries
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------ End - Add Accomplishment Modal ------------>

    <!------------ Start- View IPCR Indicator Modal ------------>
    <div id="open-ipcr-indicator" data-backdrop="static" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage IPCR Indicators</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="form-group">
                <label for="">IPCR Indicator</label>
                <input
                  v-model="ipcrIndicator.ipcr_text"
                  maxlength="150"
                  class="form-control"
                  type="text"
                  placeholder="Provide a description here"
                />
              </div>
              <button
                :disabled="editModeIpcr"
                class="btn btn-primary mr-2"
                @click="addIpcrIndicator"
              >
                Add
              </button>

              <button
                :disabled="!editModeIpcr"
                class="btn btn-success"
                @click="updateIpcrIndicator"
              >
                Update
              </button>

              <div v-if="ipcrLoader" class="d-flex align-items-center mt-3">
                <strong>Please wait...</strong>
                <div
                  class="spinner-border spinner-border-sm ml-auto"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>

              <div class="table-responsive mt-3">
                <strong class="">List of IPCR Indicators</strong>
                <table class="table mt-3 table-sm table-bordered">
                  <thead>
                    <th>#</th>
                    <th>IPCR Indicator</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(indicator, index) in ipcrIndicators"
                      :key="indicator.ar_ipcr_indicator_id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ indicator.ipcr_text }}</td>
                      <td>
                        <a
                          href="#"
                          @click.prevent="fillIpcrUpdateField(indicator)"
                        >
                          <i class="bi bi-pen-fill text-primary mx-1"> </i>
                        </a>
                        |
                        <a
                          href="#"
                          @click.prevent="deleteIpcrIndicator(indicator)"
                        >
                          <i class="bi bi-trash-fill text-danger mx-1"> </i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------ End - View IPCR Indicator Modal ------------>
  </div>
</template>

<script>
import { chedEmployees, chedEmployeesUnit } from "../../js/employees";
import { getDate, reference } from "../../js/helpers";

export default {
  name: "AccomplishmentReport",
  data() {
    return {
      ipcrLoader: false,
      editModeIpcr: false,
      editMode: false,
      dates: [],
      groupedDates: [],
      params: {
        per_page: 15,
        ar_ipcr_indicator_id: "",
        from: "",
        to: "",
      },
      chedEmployees: chedEmployees,
      chedEmployeesUnit: chedEmployeesUnit,
      DEFAULT_URL: "api/accomplishment-reports",
      ipcrIndicators: [],
      accomplishmentReports: [],
      ipcrIndicator: new Form({
        ipcr_text: "",
        ar_ipcr_indicator_id: "",
      }),
      filterLoader: false,
      ar: new Form({
        date_of_work: "",
        ar_accomplishment_id: "",
        ar_ipcr_indicator_id: "",
        details: "",
        task_count: 1,
      }),
      arData: [],
      pagination: {},
      noReviewedBy: [
        51, // gladys
        62, // maelove
        8, // kath
        57, // orphy
        82, // ian jones
      ],
    };
  },

  methods: {
    getAccomplishmentReports(page_url = null, options) {
      this.filterLoader = options.filterLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      axios
        .get(page_url, {
          params: this.params,
        })
        .then((response) => {
          this.accomplishmentReports = response.data.data;
          this.makePagination(response.data);
          this.getGroupByDates();
          this.filterLoader = false;
        })
        .catch((err) => {
          toastr.error("Oops! Something went wrong.", "Error");
          this.filterLoader = false;
        });
    },

    openUpdateEntryModal(ar) {
      this.editMode = true;
      $("#add-accomplishment").modal("show");
      this.ar.fill(ar);
    },

    updateEntry() {
      axios
        .put(
          "api/accomplishment-reports/" + this.ar.ar_accomplishment_id,
          this.ar
        )
        .then((res) => {
          toastr.success(res.data.message, "Info");
          $("#add-accomplishment").modal("hide");
          this.getAccomplishmentReports(this.DEFAULT_URL, {
            filterLoader: { enabled: false },
          });
        })
        .catch((err) => {});
    },

    deleteAccomplishment(ar) {
      if (
        confirm(
          `WARNING: This action cannot be undone. Are you sure you want to delete this entry?`
        )
      ) {
        axios
          .delete("api/accomplishment-reports/" + ar.ar_accomplishment_id)
          .then((res) => {
            toastr.success(res.data.message, "Info");
            this.getAccomplishmentReports(this.DEFAULT_URL, {
              filterLoader: { enabled: false },
            });
          })
          .catch((err) => {
            toastr.error("Oops! Something went wrong.", "Error");
          });
      }
    },

    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    submitAccomplishmentReport() {
      let btn = document.getElementById("ar-submit-btn");
      btn.disabled = true;
      btn.innerHTML = "Saving...";

      axios
        .post("api/accomplishment-reports", {
          params: {
            arData: this.arData,
          },
        })
        .then((response) => {
          toastr.success(response.data.message, "Info");
          this.getAccomplishmentReports(this.DEFAULT_URL, {
            filterLoader: { enabled: false },
          });
          $("#add-accomplishment").modal("hide");
          this.resetEntryForm();
          btn.disabled = false;
          btn.innerHTML = "Save entries";
        })
        .catch((response) => {
          toastr.error("Oops! Something went wrong.", "Error");
          btn.disabled = false;
          btn.innerHTML = "Save entries";
        });
    },

    resetEntryForm() {
      this.ar.reset();
      this.arData = [];
    },

    appendAccomplishment() {
      if (!this.isEntryValidated()) {
        toastr.error("Please make sure to fill in all fields.", "Error");
        return;
      }

      const ar = {
        ...this.ar,
        ipcr_text: (
          this.ipcrIndicators.find(
            (obj) => obj.ar_ipcr_indicator_id === this.ar.ar_ipcr_indicator_id
          ) || {}
        ).ipcr_text,
      };

      this.arData.push(ar);
      this.ar.task_count = 1;
      this.ar.details = "";
    },

    isEntryValidated() {
      return this.ar.details !== "" && this.ar.ar_ipcr_indicator_id !== "";
    },

    deleteEntry(index) {
      this.arData.splice(index, 1);
    },

    printAR() {
      this.$htmlToPaper("ar");
    },

    getDivision(id) {
      let division = "";
      this.chedEmployeesUnit.forEach((cosEmployee) => {
        if (cosEmployee.BiometricID == id) {
          division = cosEmployee.division;
        }
      });

      return division;
    },

    getSection(id) {
      let section = "";
      this.chedEmployeesUnit.forEach((cosEmployee) => {
        if (cosEmployee.BiometricID == id) {
          section = cosEmployee.unit_section;
        }
      });

      return section;
    },
    getPreparedBy(id) {
      let prepared = "";
      this.chedEmployees.forEach((chedEmployee) => {
        if (chedEmployee.BiometricID == id) {
          prepared = chedEmployee.prepared;
        }
      });

      return prepared;
    },
    getPreparedPosition(id) {
      let prepared = "";
      this.chedEmployees.forEach((chedEmployee) => {
        if (chedEmployee.BiometricID == id) {
          prepared = chedEmployee.p_position;
        }
      });

      return prepared;
    },
    getReviewed(id) {
      let prepared = "";
      this.chedEmployees.forEach((chedEmployee) => {
        if (chedEmployee.BiometricID == id) {
          prepared = chedEmployee.reviewed;
        }
      });

      return prepared;
    },
    getReviewedPosition(id) {
      let prepared = "";
      this.chedEmployees.forEach((chedEmployee) => {
        if (chedEmployee.BiometricID == id) {
          prepared = chedEmployee.r_position;
        }
      });

      return prepared;
    },

    getNotedPosition(id) {
      let noted = "";
      this.chedEmployees.forEach((chedEmployee) => {
        if (chedEmployee.BiometricID == id) {
          noted = chedEmployee.n_position;
        }
      });

      return noted;
    },

    openIpcrIndicatorsModal() {
      $("#open-ipcr-indicator").modal("show");
    },

    openAddAccomplishmentReportModal() {
      this.editMode = false;
      this.resetEntryForm();
      $("#add-accomplishment").modal("show");
      this.ar.date_of_work = moment().format("YYYY-MM-DD");
    },

    getGroupByDates() {
      this.groupedDates = this.accomplishmentReports.reduce((acc, obj) => {
        const key = obj["date_of_work"];
        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(obj);
        return acc;
      }, {});

      this.dates = Object.keys(this.groupedDates).reverse();
    },

    getIpcrIndicators() {
      axios
        .get("api/ipcr")
        .then((response) => {
          this.ipcrIndicators = response.data;
        })
        .catch((error) => {
          toastr.error("Oops! Something went wrong.", "Error");
        });
    },
    addIpcrIndicator() {
      if (this.ipcrIndicator.ipcr_text == "") {
        toastr.error("Please fill in required field.", "Error");
        return;
      }

      this.ipcrLoader = true;

      axios
        .post("api/ipcr", {
          ipcrIndicator: this.ipcrIndicator.ipcr_text,
        })
        .then((response) => {
          toastr.success(response.data.message, "Info");
          this.loading = false;
          this.getIpcrIndicators();
          this.ipcrIndicator.reset();
          this.ipcrLoader = false;
        })
        .catch((response) => {
          toastr.error("Oops! Something went wrong.", "Error");
          this.ipcrLoader = false;
        });
    },

    fillIpcrUpdateField(indicator) {
      this.ipcrIndicator.fill(indicator);
      this.editModeIpcr = true;
    },

    updateIpcrIndicator() {
      this.ipcrLoader = true;

      axios
        .put("api/ipcr/" + this.ipcrIndicator.ar_ipcr_indicator_id, {
          ipcr_text: this.ipcrIndicator.ipcr_text,
        })
        .then((res) => {
          toastr.success(res.data.message, "Infor");
          this.ipcrIndicator.reset();
          this.getIpcrIndicators();
          this.editModeIpcr = false;
          this.ipcrLoader = false;
        })
        .catch((err) => {
          toastr.error("Oops! Something went wrong.", "Error");
          this.editModeIpcr = false;
          this.ipcrLoader = false;
        });
    },

    deleteIpcrIndicator(indicator) {
      if (
        confirm(
          `WARNING: This action cannot be undone. Are you sure you want to delete this entry?`
        )
      ) {
        this.ipcrLoader = true;

        axios
          .delete("api/ipcr/" + indicator.ar_ipcr_indicator_id)
          .then((res) => {
            toastr.success(res.data.message, "Info");
            this.getIpcrIndicators();
            this.ipcrLoader = false;
          })
          .catch((err) => {
            toastr.error("Oops! Something went wrong.", "Error");
            this.ipcrLoader = false;
          });
      }
    },
    getNoted() {
      let noted = "";
      this.chedEmployees.forEach((chedEmployee) => {
        if (chedEmployee.BiometricID == this.$store.state.user.personnel_id) {
          noted = chedEmployee.noted;
        }
      });

      return noted;
    },
  },
  computed: {
    totalTaskCount() {
      return this.accomplishmentReports.reduce(
        (total, task) => total + task.task_count,
        0
      );
    },
    getDate,
    reference,
  },
  mounted() {
    this.getAccomplishmentReports(this.DEFAULT_URL, {
      filterLoader: { enabled: false },
    });
    this.getIpcrIndicators();
  },
};
</script>
