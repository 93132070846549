export const chedEmployees = [
  {
    BiometricID: "8",
    FirstName: "Kathleen",
    LastName: "Castanares",
    prepared: "KATHLEEN D. CASTAÑARES",
    p_position: "Project Technical Staff I - Budget Assistant",
    reviewed: "GESELLE M. FRANCISCO, CPA",
    r_position: "OIC, Chief Administrative Officer",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "9",
    FirstName: "Sarah",
    LastName: "Moron",
    prepared: "SARAH T. MORON",
    p_position: "Project Technical Staff I, UniFAST/Accounting Staff",
    reviewed: "GESELLE M. FRANCISCO, CPA",
    r_position: "Accountant III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "14",
    FirstName: "Calwin",
    LastName: "Alegre",
    prepared: "JOBERT CALWIN M. ALEGRE",
    p_position: "Project Technical Staff III - MIS",
    reviewed: "AMY C. SAAVEDRA, MSCA",
    r_position: "Education Supervisor II - MIS Head",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "15",
    FirstName: "Daisy",
    LastName: "Grana",
    prepared: "DAISY I. GRANA",
    p_position: "Project Technical Staff II, UniFAST",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "16",
    FirstName: "May Trinity",
    LastName: "De La Torre",
    prepared: "MAY TRINITY N. DE LA TORRE",
    p_position: "Project Technical Staff II, UniFAST",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
    noted: "ALMA S. PATRON, JD, MSW, RSW",
    n_position: "OIC, Office of the Director IV",
  },
  {
    BiometricID: "17",
    FirstName: "Alexis Dave",
    LastName: "Sanmiguel",
    prepared: "ALEXIS DAVE M. SAN MIGUEL",
    p_position: "Project Technical Staff I",
    reviewed: "CRISELDO C. CALINAWAN, PHD, PECE",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "18",
    FirstName: "Krizia Meijel",
    LastName: "Mellijor",
    prepared: "KRIZIA MEIJEL MELLIJOR",
    p_position: "Project Technical Staff II",
    reviewed: "GESELLE M. FRANCISCO, CPA",
    r_position: "OIC, Chief Administrative Officer",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "19",
    FirstName: "Valerie",
    LastName: "Vismanos",
    prepared: "VALERIE EDFLO B. VISMANOS",
    p_position: "Project Technical Staff I - UAQTE",
    reviewed: "EVER JOY G. RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "21",
    FirstName: "Heidi",
    LastName: "Paradero",
    prepared: "HEIDI M. PARADERO",
    p_position: "Project Technical Staff II",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "22",
    FirstName: "Jo",
    LastName: "Orendain",
    prepared: "JO SHEILA ORENDAIN",
    p_position: "Project Technical Staff I",
    reviewed: "REVELYN P. BRINA, MAED",
    r_position: "Administrative Officer III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "23",
    FirstName: "Eleazar",
    LastName: "Sumalinog",
    prepared: "ELEAZAR A. SUMALINOG",
    p_position: "",
    reviewed: "",
    r_position: "",
    noted: "",
    n_position: "",
  },
  {
    BiometricID: "30",
    FirstName: "Ronan",
    LastName: "Otaza",
    prepared: "RONAN E. OTAZA",
    p_position: "Project Technical Staff I - CSP",
    reviewed: "EVER JOY G. RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "38",
    FirstName: "Bill",
    LastName: "Baconguis",
    prepared: "BILL CARLO R. BACONGUIS",
    p_position: "Project Technical Staff II, UniFAST/MIS",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "39",
    FirstName: "Christine",
    LastName: "Escol",
    prepared: "CHRISTINE R. ESCOL",
    p_position: "Support Staff I - UAQTE",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "42",
    FirstName: "Charles Jem",
    LastName: "Montes",
    prepared: "CHARLES JEM M. MONTES",
    p_position: "Procurement/Admin Staff",
    reviewed: "ANGEL A. ASIS, MM-CAR",
    r_position: "Administrative Aide VI/HR",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "43",
    FirstName: "Trician Faye",
    LastName: "Gozon",
    prepared: "TRICIAN FAYE D. GOZON",
    p_position: "Unifast Staff",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "46",
    FirstName: "Maria Mercedes",
    LastName: "Rebita",
    prepared: "MA. MERCEDES P. REBITA",
    p_position: "Project Technical Staff I - Records Section",
    reviewed: "REVELYN P. BRINA, MAED",
    r_position: "Administrative Officer III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "51",
    FirstName: "Gladys",
    LastName: "Concha",
    prepared: "GLADYS O. CONCHA",
    p_position: "CHED-COA Support Staff",
    reviewed: "JONIFEL T. SAN JUAN, CPA",
    r_position: "State Auditor III",
    noted: "NOEME ROSE D. MANI, CPA, MM",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "52",
    FirstName: "Kershey Reign",
    LastName: "Dueñas",
    prepared: "KERSHEY REIGN E. DUEÑAS",
    p_position: "Project Technical Staff III, IZN",
    reviewed: "AMY C. SAAVEDRA, MSCA",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "53",
    FirstName: "Mona Pia",
    LastName: "Galing",
    prepared: "MONA PIA G. GALING",
    p_position: "Project Technical Staff I - HRDS",
    reviewed: "ANGEL A. ASIS, MM-CAR",
    r_position: "Administrative Aide VI/HR",
    noted: "ALMA S. PATRON, JD, MSW, RSW",
    n_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "55",
    FirstName: "Christy Joy",
    LastName: "Lajos",
    prepared: "CHRISTY JOY E. LAJOS",
    p_position: "Cashier Support Staff",
    reviewed: "FRANCIS D. ILAGAN",
    r_position: "Administrative Officer III",
    noted: "ALMA SY-PATRON, JD, MSW, RSW",
    n_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "57",
    FirstName: "Orpheus",
    LastName: "Cubillas",
    prepared: "ORPHEUS A. CUBILLAS",
    p_position: "Project Technical Staff II - Sports Coordinator",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "62",
    FirstName: "Maelove",
    LastName: "Dagohoy",
    prepared: "MAELOVE I. DAGOHOY",
    p_position: "Project Technical Staff III -  Accounting Unit",
    reviewed: "GESELLE M. FRANCISCO, CPA",
    r_position: "OIC, Chief Administrative Officer",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "63",
    FirstName: "JESIELYN MAE",
    LastName: "ADO",
    prepared: "JESIELYN MAE I. ADO",
    p_position:
      "Project Technical Staff II -  Regional Statistical Focal Officer",
    reviewed: "AMY C. SAAVEDRA, MSCA",
    r_position: "Education Supervisor II - MIS Head",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "65",
    FirstName: "Eljean Rose",
    LastName: "Sande",
    prepared: "ELJEAN ROSE U. SANDE",
    p_position: "CSP Support Staff",
    reviewed: "EVER JOY G. RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "66",
    FirstName: "Ceaka Angel",
    LastName: "Pacot",
    prepared: "CEAKA ANGEL P. PACOT",
    p_position: "Records Support Staff",
    reviewed: "REVELYN P. BRINA, MAED",
    r_position: "Administrative Officer III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "67",
    FirstName: "Christopher",
    LastName: "Hingpit",
    prepared: "CHRISTOPHER A. HINGPIT",
    p_position: "Project Technical Staff III",
    reviewed: "ROLYNA S. TRUGILLO, PHD, LPT",
    r_position: "K12/SIKAP Alter Focal Person",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "68",
    FirstName: "Marti Jose",
    LastName: "Maban",
    prepared: "MARTI JOSE M. MABAN",
    p_position: "Project Technical Staff II",
    reviewed: "CRISELDO C. CALINAWAN,PHD, PECE",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "73",
    FirstName: "Angelie",
    LastName: "Tiempo",
    prepared: "ANGELIE B. TIEMPO",
    p_position: "Support Staff I - UAQTE",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },

  //REGULAR  EMPLOYEES
  {
    BiometricID: "1",
    FirstName: "Alma",
    LastName: "Patron",
    prepared: "ALMA S. PATRON, JD, MSW, RSW",
    p_position: "Chief Administrative Officer",
    reviewed: "GEORGE M. COLORADO, PHD, CESO III",
    r_position: "Director IV",
  },
  {
    BiometricID: "2",
    FirstName: "Geselle",
    LastName: "Francisco",
    prepared: "GESELLE G. FRANCISCO, CPA",
    p_position: "Accountant II",
    reviewed: "ALMA SY-PATRON, LLB, MSW",
    r_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "3",
    FirstName: "Francis",
    LastName: "Ilagan",
    prepared: "FRANCIS D. ILAGAN",
    p_position: "Administrative Officer III",
    reviewed: "ALMA SY-PATRON, LLB, MSW",
    r_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "4",
    FirstName: "Haemaccel",
    LastName: "Palima",
    prepared: "HAEMACEL I. PALIMA",
    p_position: "Admin Aide III/Supply Officer",
    reviewed: "ALMA SY-PATRON, LLB, MSW",
    r_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "5",
    FirstName: "Misael",
    LastName: "Cabang",
    prepared: "MISAEL S. CABANG",
    p_position: "Admin Aide III",
    reviewed: "ALMA SY-PATRON, LLB, MSW",
    r_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "13",
    FirstName: "Julia Felisa",
    LastName: "Martinez",
    prepared: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    p_position: "Chief Education Program Specialist",
    reviewed: "GEORGE M. COLORADO, PHD, CESO III",
    r_position: "Director IV",
    noted: "GEORGE M. COLORADO, PHD, CESO III",
    n_position: "Director IV",
  },
  {
    BiometricID: "26",
    FirstName: "Angel",
    LastName: "Asis",
    prepared: "ANGEL A. ASIS,MM-CAR",
    p_position: "Administrative Aide VI/HR",
    reviewed: "ALMA SY-PATRON, LLB, MSW",
    r_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "28",
    FirstName: "Revelyn",
    LastName: "Brina",
    prepared: "REVELYN P. BRINA, MAED",
    p_position: "Administrative Officer III",
    reviewed: "ALMA SY-PATRON, LLB, MSW",
    r_position: "Chief Administrative Officer",
  },
  {
    BiometricID: "59",
    FirstName: "Noeme",
    LastName: "Mani",
    prepared: "EVER JOY G. RABADAN, MAEM, MSc",
    p_position: "Education Supervisor II",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "32",
    FirstName: "Criseldo",
    LastName: "Calinawan",
    prepared: "ENGR. CRISELDO C. CALINAWAN, PHD",
    p_position: "Education Supervisor II",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "34",
    FirstName: "Amy",
    LastName: "Saavedra",
    prepared: "AMY C. SAAVEDRA, MSCA",
    p_position: "Education Supervisor II",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "36",
    FirstName: "Fred",
    LastName: "Amihan",
    prepared: "FREDERICK C. AMIHAN, PHD",
    p_position: "Education Supervisor II",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "37",
    FirstName: "Rolyna",
    LastName: "Trugillo",
    prepared: "ROLYNA S. TRUGILLO, PHD",
    p_position: "Education Supervisor II",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "61",
    FirstName: "Ever Joy",
    LastName: "Rabadan",
    prepared: "EVER JOY G. RABADAN, MAEM, MSc.",
    p_position: "Education Supervisor II",
    reviewed: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    r_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "69",
    FirstName: "Katrina Joy",
    LastName: "Pupos",
    prepared: "KATRINA JOY F. PUPOS",
    p_position: "Cashier Support Staff",
    reviewed: "FRANCIS D. ILAGAN",
    r_position: "Administrative Officer III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },

  {
    BiometricID: "72",
    FirstName: "Lennie",
    LastName: "Juezan",
    prepared: "LENNIE A. JUEZAN",
    p_position: "Records Support Staff",
    reviewed: "REVELYN P. BRINA, MAED",
    r_position: "Administrative Officer III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },

  {
    BiometricID: "70",
    FirstName: "Anilou",
    LastName: "Cabarrubias",
    prepared: "ANILOU G. CABARRUBIAS",
    p_position: "CSP Support Staff",
    reviewed: "EVER JOY G. RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },

  {
    BiometricID: "71",
    FirstName: "Nicol",
    LastName: "Capunong",
    prepared: "NICOL G. CAPUNONG",
    p_position: "Driver",
    reviewed: "HAEMACEL I. PALIMA",
    r_position: "Admin Aide III/Supply Officer",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "74",
    FirstName: "Jenny Pearl",
    LastName: "Eledia",
    prepared: "JENNY PEARL ELEDIA",
    p_position: "Administrative Support Staff",
    reviewed: "ANGEL A. ASIS",
    r_position: "Administrative Aide VI",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "75",
    FirstName: "Hannah Franchesca",
    LastName: "Tabal",
    prepared: "HANNAH FRANCHESCA M. TABAL",
    p_position: "Project Technical Staff II, UniFAST",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "76",
    FirstName: "Kate Russel",
    LastName: "Militante",
    prepared: "KATE RUSSEL B. MILITANTE",
    p_position: "CSP Support Staff",
    reviewed: "EVER JOY G. RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "77",
    FirstName: "Jobelyn",
    LastName: "Intan",
    prepared: "JOBELYN INTAN",
    p_position: "CSP Support Staff",
    reviewed: "EVER JOY G. RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "78",
    FirstName: "Edrin Kate",
    LastName: "Salingay",
    prepared: "EDRIN KATE SALINGAY",
    p_position: "CSP Support Staff",
    reviewed: "EVER JOY G. RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "79",
    FirstName: "Dennis",
    LastName: "Castrodes",
    prepared: "DENNIS G. CASTRODES",
    p_position: "Project Technical Staff III",
    reviewed: "ROLYNA S. TRUGILLO, PHD, LPT",
    r_position: "K12/SIKAP Alter Focal Person",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "80",
    FirstName: "Florence",
    LastName: "Lapinig",
    prepared: "Florence M. Lapinig",
    p_position: "Project Technical Staff II, UniFAST",
    reviewed: "GESELLE M. FRANCISCO, CPA",
    r_position: "Accountant III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "81",
    FirstName: "Geliemae",
    LastName: "Bajan",
    prepared: "GELIEMAE G. BAJAN",
    p_position: "Project Technical Staff II",
    reviewed: "GESELLE M. FRANCISCO, CPA",
    r_position: "OIC - Chief Administrative Officer",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC - Chief Administrative Officer",
  },
  {
    BiometricID: "82",
    FirstName: "Ian Jones",
    LastName: "Libarios",
    prepared: "IAN JONES LIBARIOS",
    p_position: "CHED-COA Support Staff",
    reviewed: "JONIFEL T. SAN JUAN, CPA",
    r_position: "State Auditor III",
    noted: "NOEME ROSE D. MANI, CPA, MM",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "83",
    FirstName: "Samuel",
    LastName: "Saragena",
    prepared: "SAMUEL P. SARAGENA",
    p_position: "Project Technical Staff II, UniFAST",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "84",
    FirstName: "Alexander",
    LastName: "Cultura",
    prepared: "ALEXANDER C. ZAMBRANO, JR.",
    p_position: "Project Technical Staff III",
    reviewed: "ROLYNA S. TRUGILLO, PHD, LPT",
    r_position: "K12/SIKAP Alter Focal Person",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "86",
    FirstName: "Reizzel Ann",
    LastName: "Berro",
    prepared: "REIZZEL ANN BERRO",
    p_position: "Project Technical Staff II",
    reviewed: "ROLYNA S. TRUGILLO, PHD, LPT",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "90",
    FirstName: "Vincent",
    LastName: "Galinato",
    prepared: "VINCENT T. GALINATO",
    p_position: "Project Support Staff IV",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "88",
    FirstName: "Caliel Bless",
    LastName: "Domingo",
    prepared: "CALIEL BLESS M. DOMINGO",
    p_position: "Cashier Support Staff",
    reviewed: "FRANCIS D. ILAGAN",
    r_position: "Administrative Officer III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "85",
    FirstName: "Jimmy",
    LastName: "Belarmino",
    prepared: "Jimmy Belarmino",
    p_position: "Project Support Staff IV",
    reviewed: "Haemaccel I. Palima",
    r_position: "Admin Aide III/Supply Officer",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "87",
    FirstName: "Charlyn",
    LastName: "Manos",
    prepared: "CHARLYN A. MANOS",
    p_position: "CSP Support Staff",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "89",
    FirstName: "Hashana Rosh",
    LastName: "Ibayan",
    prepared: "HASHANA ROSH D. IBAYAN",
    p_position: "Project Support Staff IV",
    reviewed: "EVER JOY RABADAN, MAEM, MSc",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
  {
    BiometricID: "92",
    FirstName: "John Angel",
    LastName: "Bollozos",
    prepared: "JOHN ANGEL S. BOLLOZOS",
    p_position: "Records Support Staff",
    reviewed: "REVELYN P. BRINA, MAED",
    r_position: "Administrative Officer III",
    noted: "NOEME ROSE D. MANI, MM, CPA",
    n_position: "OIC, Chief Administrative Officer",
  },
  {
    BiometricID: "91",
    FirstName: "Margarette Faye",
    LastName: "Bito",
    prepared: "MARGARETTE FAYE U. BITO",
    p_position: "Project Technical Staff III, IZN",
    reviewed: "AMY C. SAAVEDRA, MSCA",
    r_position: "Education Supervisor II",
    noted: "JULIA FELISA C. MARTINEZ, PHD, LPT",
    n_position: "Chief Education Program Specialist",
  },
];

export const chedEmployeesUnit = [
  {
    BiometricID: "8",
    FirstName: "Kathleen",
    LastName: "Castanares",
    division: "ADMINISTRATIVE",
    unit_section: "BUDGET",
  },
  {
    BiometricID: "9",
    FirstName: "Sarah",
    LastName: "Moron",
    division: "ADMINISTRATIVE",
    unit_section: "ACCOUNTING",
  },
  {
    BiometricID: "11",
    FirstName: "Judith",
    LastName: "Makidato",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },
  {
    BiometricID: "14",
    FirstName: "Calwin",
    LastName: "Alegre",
    division: "TECHNICAL",
    unit_section: "MIS",
  },
  {
    BiometricID: "15",
    FirstName: "Daisy",
    LastName: "Grana",
    division: "TECHNICAL",
    unit_section: "UniFAST",
  },
  {
    BiometricID: "16",
    FirstName: "May Trinity",
    LastName: "Narca",
    division: "TECHNICAL",
    unit_section: "UniFAST",
  },
  {
    BiometricID: "17",
    FirstName: "Alexis Dave",
    LastName: "Sanmiguel",
    division: "TECHNICAL",
    unit_section: "MARITIME",
  },
  {
    BiometricID: "18",
    FirstName: "Krizia Meijel",
    LastName: "Mellijor",
    division: "ADMINISTRATIVE",
    unit_section: "ACCOUNTING",
  },
  {
    BiometricID: "19",
    FirstName: "Valerie",
    LastName: "Vismanos",
    division: "TECHNICAL",
    unit_section: "UniFAST",
  },
  {
    BiometricID: "21",
    FirstName: "Heidi",
    LastName: "Paradero",
    division: "TECHNICAL",
    unit_section: "UniFAST",
  },
  {
    BiometricID: "22",
    FirstName: "Jo",
    LastName: "Orendain",
    division: "ADMINISTRATIVE",
    unit_section: "FRONT-DESK",
  },
  {
    BiometricID: "23",
    FirstName: "Eleazar",
    LastName: "Sumalinog",
    division: "ADMINISTRATIVE",
    unit_section: "GENERAL SERVICES",
  },
  {
    BiometricID: "30",
    FirstName: "Ronan",
    LastName: "Otaza",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "38",
    FirstName: "Bill",
    LastName: "Baconguis",
    division: "TECHNICAL",
    unit_section: "MIS",
  },
  {
    BiometricID: "39",
    FirstName: "Christine",
    LastName: "Escol",
    division: "TECHNICAL",
    unit_section: "UNIFAST",
  },
  {
    BiometricID: "40",
    FirstName: "Shiena",
    LastName: "Balios",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "41",
    FirstName: "Rose Antoniet",
    LastName: "Jamero",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "42",
    FirstName: "Charles Jem",
    LastName: "Montes",
    division: "ADMINISTRATIVE",
    unit_section: "PROCUREMENT",
  },
  {
    BiometricID: "43",
    FirstName: "Trician Faye",
    LastName: "Gozon",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },
  {
    BiometricID: "44",
    FirstName: "Jenver",
    LastName: "Canlas",
    division: "ADMINISTRATIVE",
    unit_section: "PROCUREMENT",
  },
  {
    BiometricID: "46",
    FirstName: "Maria Mercedes",
    LastName: "Rebita",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },
  {
    BiometricID: "47",
    FirstName: "Rowena",
    LastName: "Japson",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },
  {
    BiometricID: "48",
    FirstName: "Michelle",
    LastName: "Caingles",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },
  {
    BiometricID: "49",
    FirstName: "Resa",
    LastName: "Donayre",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "51",
    FirstName: "Gladys",
    LastName: "Concha",
    division: "ADMINISTRATIVE",
    unit_section: "CHED-COA",
  },
  {
    BiometricID: "52",
    FirstName: "Kershey Reign",
    LastName: "Dueñas",
    division: "TECHNICAL",
    unit_section: "IZN",
  },
  {
    BiometricID: "53",
    FirstName: "Mona Pia",
    LastName: "Galing",
    division: "ADMINISTRATIVE",
    unit_section: "HR",
  },
  {
    BiometricID: "54",
    FirstName: "Jan Myrtle",
    LastName: "Villanueva",
    division: "TECHNICAL",
    unit_section: "MIS",
  },
  {
    BiometricID: "55",
    FirstName: "Christy Joy",
    LastName: "Lajos",
    division: "ADMINISTRATIVE",
    unit_section: "CASHIER SECTION",
  },
  {
    BiometricID: "56",
    FirstName: "Junlou",
    LastName: "Tordos",
    division: "ADMINISTATIVE",
    unit_section: "HR",
  },
  {
    BiometricID: "57",
    FirstName: "Orpheus",
    LastName: "Cubillas",
    division: "TECHNICAL",
    unit_section: "CHED SPORTS UNIT",
  },
  {
    BiometricID: "58",
    FirstName: "Felgen Mark",
    LastName: "Gabin",
    division: "ADMINISTRATIVE",
    unit_section: "PROCUREMENT",
  },
  {
    BiometricID: "65",
    FirstName: "Eljean Rose",
    LastName: "Sande",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "63",
    FirstName: "Jesielyn Mae",
    LastName: "Ado",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "67",
    FirstName: "Christopher",
    LastName: "Hingpit",
    division: "TECHNICAL",
    unit_section: "LGSO",
  },
  {
    BiometricID: "68",
    FirstName: "Marti Jose",
    LastName: "Maban",
    division: "TECHNICAL",
    unit_section: "MARITIME",
  },
  {
    BiometricID: "73",
    FirstName: "Angelie",
    LastName: "Tiempo",
    division: "TECHNICAL",
    unit_section: "UniFAST",
  },
  {
    BiometricID: "82",
    FirstName: "Ian Jones",
    LastName: "Libarios",
    division: "ADMINISTRATIVE",
    unit_section: "CHED-COA",
  },

  {
    BiometricID: "83",
    FirstName: "Samuel",
    LastName: "Saragena",
    division: "TECHNICAL",
    unit_section: "UniFAST",
  },

  //REGULAR EMPLOYEE
  {
    BiometricID: "1",
    FirstName: "Alma",
    LastName: "Patron",
    division: "ADMINISTRATIVE",
    unit_section: "ADMIN",
  },
  {
    BiometricID: "2",
    FirstName: "Geselle",
    LastName: "Francisco",
    division: "ADMINISTRATIVE",
    unit_section: "ACCOUNTING",
  },
  {
    BiometricID: "3",
    FirstName: "Francis",
    LastName: "Ilagan",
    division: "ADMINISTRATIVE",
    unit_section: "HR",
  },
  {
    BiometricID: "4",
    FirstName: "Haemaccel",
    LastName: "Palima",
    division: "ADMINISTRATIVE",
    unit_section: "PROCUREMENT",
  },
  {
    BiometricID: "5",
    FirstName: "Misael",
    LastName: "Cabang",
    division: "ADMINISTRATIVE",
    unit_section: "PROCUREMENT",
  },
  {
    BiometricID: "13",
    FirstName: "Julia Felisa",
    LastName: "Martinez",
    division: "TECHNICAL",
    unit_section: "MONITORING AND EVALUATION",
  },
  {
    BiometricID: "26",
    FirstName: "Angel",
    LastName: "Asis",
    division: "ADMINISTRATIVE",
    unit_section: "BUDGET",
  },
  {
    BiometricID: "28",
    FirstName: "Revelyn",
    LastName: "Brina",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },
  {
    BiometricID: "59",
    FirstName: "Noeme",
    LastName: "Mani",
    division: "TECHNICAL",
    unit_section: "MPSD",
  },
  {
    BiometricID: "32",
    FirstName: "Criseldo",
    LastName: "Calinawan",
    division: "TECHNICAL",
    unit_section: "MPSD",
  },
  {
    BiometricID: "34",
    FirstName: "Amy",
    LastName: "Saavedra",
    division: "TECHNICAL",
    unit_section: "MIS",
  },
  {
    BiometricID: "36",
    FirstName: "Fred",
    LastName: "Amihan",
    division: "TECHNICAL",
    unit_section: "MPSD",
  },
  {
    BiometricID: "37",
    FirstName: "Rolyna",
    LastName: "Trugillo",
    division: "TECHNICAL",
    unit_section: "MPSD",
  },
  {
    BiometricID: "61",
    FirstName: "Ever Joy",
    LastName: "Rabadan",
    division: "TECHNICAL",
    unit_section: "MPSD",
  },
  {
    BiometricID: "62",
    FirstName: "Maelove",
    LastName: "Dagohoy",
    division: "ADMINISTRATIVE",
    unit_section: "ACCOUNTING",
  },

  {
    BiometricID: "69",
    FirstName: "Katrina Joy",
    LastName: "Pupos",
    division: "ADMINISTRATIVE",
    unit_section: "CASHIER",
  },

  {
    BiometricID: "72",
    FirstName: "Lennie",
    LastName: "Juezan",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },

  {
    BiometricID: "66",

    FirstName: "Ceaka Angel",
    LastName: "Pacot",
    division: "ADMINISTRATIVE",
    unit_section: "RECORDS",
  },

  {
    BiometricID: "71",
    FirstName: "Nicol",
    LastName: "Capunong",
    division: "ADMINISTRATIVE",
    unit_section: "GENERAL SERVICES",
  },
  {
    BiometricID: "74",
    FirstName: "Jenny Pearl",
    LastName: "Eledia",
    division: "ADMINISTRATIVE",
    unit_section: "PROCUREMENT",
  },
  {
    BiometricID: "75",
    FirstName: "Hannah Franchesca",
    LastName: "Tabal",
    division: "TECHNICAL",
    unit_section: "UniFAST",
  },
  {
    BiometricID: "76",
    FirstName: "Kate Russel",
    LastName: "Militante",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "77",
    FirstName: "Jobelyn",
    LastName: "Intan",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "78",
    FirstName: "Edrin Kate",
    LastName: "Salingay",
    division: "TECHNICAL",
    unit_section: "CSP",
  },
  {
    BiometricID: "79",
    FirstName: "Dennis",
    LastName: "Castrodes",
    division: "TECHNICAL",
    unit_section: "LGSO",
  },
  {
    BiometricID: "81",
    FirstName: "Geliemae",
    LastName: "Bajan",
    division: "ADMINISTRATIVE",
    unit_section: "ACCOUNTING",
  },
  {
    BiometricID: "84",
    FirstName: "Alexander",
    LastName: "Zambrano",
    division: "Technical",
    unit_section: "SIKAP",
  },
  {
    BiometricID: "86",
    FirstName: "Reizzel Ann",
    LastName: "Berro",
    division: "Technical",
    unit_section: "SIKAP",
  },
  {
    BiometricID: "90",
    FirstName: "Vincent",
    LastName: "Galinato",
    division: "Technical",
    unit_section: "ACEF",
  },
  {
    BiometricID: "85",
    FirstName: "Jimmy",
    LastName: "Belarmino",
    division: "Admin",
    unit_section: "GSS",
  },
  {
    BiometricID: "88",
    FirstName: "Caliel Bless",
    LastName: "Domingo",
    division: "Admin",
    unit_section: "Cashier",
  },
  {
    BiometricID: "87",
    FirstName: "Charlyn",
    LastName: "Manos",
    division: "Technical",
    unit_section: "CSP",
  },
  {
    BiometricID: "89",
    FirstName: "Hashana Rosh",
    LastName: "Ibayan",
    division: "Technical",
    unit_section: "CSP",
  },
  {
    BiometricID: "92",
    FirstName: "John Angel",
    LastName: "Bollozos",
    division: "Admin",
    unit_section: "Records",
  },
  {
    BiometricID: "91",
    FirstName: "Margarette Faye",
    LastName: "Bito",
    division: "Technical",
    unit_section: "IZN",
  },
];
